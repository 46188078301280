<template>
  <div v-if="isUpdating" class="w-full flex items-center justify-center">
    <Spinner class="text-primary-900 w-6 h-6" />
  </div>
  <GiftWrapping
    v-else
    :value="giftBoxMessage"
    @update="updateBoxMessage"
    @add="addBoxMessage"
    @remove="removeBoxMessage"
  />
</template>
<script setup lang="ts">
const props = defineProps({
  itemId: {
    type: String,
    required: true,
  },
  optionId: {
    type: [String, Number],
    required: true,
  },
  giftBoxMessage: {
    type: String,
    default: '',
    required: false,
  },
});

const { updateCustomizationOption, addCustomizationOption } = useCartItemCustomizationOption(
  computed(() => props.itemId),
);

const { success, error } = useAlerts();
const isUpdating = ref(false);

async function updateBoxMessage(message: string) {
  try {
    isUpdating.value = true;
    await updateCustomizationOption(props.optionId as number, message);

    success('Gift wrapping message updated');
    isUpdating.value = false;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    error('Error Updating Setting Gift Wrapping Message');
    isUpdating.value = false;
  }
}

async function addBoxMessage(message: string) {
  try {
    isUpdating.value = true;
    await addCustomizationOption(props.optionId as number, message);

    success('Gift wrapping message updated');
    isUpdating.value = false;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    error('Error Adding Setting Gift Wrapping Message');
    isUpdating.value = false;
  }
}

async function removeBoxMessage() {
  try {
    isUpdating.value = true;
    await updateBoxMessage('N/A');

    success('Gift wrapping message updated');
    isUpdating.value = false;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    error('Error Removing Setting Gift Wrapping Message');
    isUpdating.value = false;
  }
}
</script>
