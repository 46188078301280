<template>
  <div class="flex flex-col">
    <div class="flex space-x-1 items-center">
      <CheckboxInput
        v-if="!readonly"
        id="gift-wrapping"
        :value="activeGiftWrapping"
        name="gift-wrapping"
        class="mr-2"
        :label="$t('title')"
        @input="(e: boolean) => (activeGiftWrapping = e)"
      />

      <svg-icon-i-icon />
    </div>

    <section v-if="messageInput && messageInput !== 'N/A' && isSubmitted" class="mt-6 bg-primary-2-07 p-6 rounded-3xs">
      <header class="text-sm text-gray-500">CUSTOM MESSAGE</header>

      <div v-if="!readonly" class="mt-2 flex justify-between flex-col gap-5 md:flex-row md:items-center">
        <p class="mr-5 font-semibold">"{{ messageInput === 'N/A' ? '' : messageInput }}"</p>
        <div class="flex items-center gap-8">
          <button class="text-primary-900 mr-4 flex items-center lg:hidden" @click="edit">
            <svg-icon-edit class="w-5 h-5 me-2" />
            {{ $t('edit') }}
          </button>

          <button class="text-primary-900 mr-4 items-center hidden lg:flex" @click="edit">
            <svg-icon-edit class="w-5 h-5 me-2" />
            {{ $t('edit') }}
          </button>

          <button type="button" class="text-red-700 flex items-center" @click="deleteMessage">
            <svg-icon-trash class="w-5 h-5 me-2" />
            {{ $t('delete') }}
          </button>
        </div>
      </div>
    </section>

    <template v-if="!readonly">
      <section v-if="!isSubmitted && activeGiftWrapping" class="hidden mt-3 mb-8 px-6 lg:px-0 w-full">
        <TextInput
          id="message"
          :value="messageInput === 'N/A' ? '' : messageInput"
          name="message"
          type="textarea"
          label="Message"
          cols="10"
          rows="1"
          placeholder="Add a message with max 130 characters"
          filled
          @input="(e: string) => (messageInput = e)"
        />

        <button
          class="w-full opacity-30 text-lg space-x-2 [ flex items-center ]"
          :class="{ 'opacity-100': messageInput }"
          @click="submitMessage"
        >
          <span class="font-medium text-lg mr-4 text-primary-900">{{ $t('submit-message') }}</span>
          <!-- <svg-icon-nav-arrow width="51" height="30" class="text-primary-900" /> -->
        </button>
      </section>
      <ClientOnly>
        <VariantModalDialog
          :value="!isSubmitted && activeGiftWrapping"
          title="Gift Message"
          type="small"
          class="md:hidden"
          @input="submitMessage"
        >
          <section class="mt-3 mb-8 w-full px-5 md:px-0 bg-page-bg rounded-6sm">
            <header class="text-1.5xl font-semibold">
              {{ $t('wrapThisItem') }}
            </header>
            <TextInput
              id="message"
              :value="messageInput === 'N/A' ? '' : messageInput"
              name="message"
              type="textarea"
              label="Custom Message"
              cols="10"
              rows="1"
              class="mt-8"
              placeholder="Add a message with max 130 characters"
              filled
              light
              @input="(e: string) => (messageInput = e)"
            />

            <AppButton
              type="button"
              inverted
              class="w-full bg-primary-900 text-white rounded-2xs mt-8 text-lg py-4 justify-center"
              @click="submitMessage"
            >
              Submit Message
            </AppButton>
          </section>
        </VariantModalDialog>
      </ClientOnly>
    </template>
  </div>
</template>
<script setup lang="ts">
/**
 * Gift wrapping component
 *
 * emits input event when the gift wrapping is changed
 * input = '' if the gift wrapping is deleted or deactivated ( action remove)
 * input = placeholder if the gift wrapping is added in the first time  ( action add )
 * input = message if the gift wrapping is edited ( action edit )
 *
 * */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emptyPlaceHolder = 'N/A';
const props = defineProps<{
  value: string;
  readonly?: boolean;
}>();

const activeGiftWrapping = ref(!!(props.value && props.value !== emptyPlaceHolder));

const messageInput = ref(props.value);
const isSubmitted = ref(!!props.value);

const emit = defineEmits<{
  input: [value: string];
  remove: [value: string];
  add: [value: string];
  update: [value: string];
}>();

const { t: $t } = useI18n({
  useScope: 'local',
});
watch(
  () => props.value,
  value => {
    messageInput.value = value;
  },
);

watch(activeGiftWrapping, (newValue, oldValue) => {
  if (oldValue && !newValue) {
    isSubmitted.value = false;
    emit('remove', '');
  }

  if (!oldValue && newValue) {
    isSubmitted.value = false;
    // emit('add', emptyPlaceHolder);
  }
});

function edit() {
  isSubmitted.value = false;
}

function deleteMessage() {
  isSubmitted.value = false;
  activeGiftWrapping.value = false;
  emit('remove', emptyPlaceHolder);
}

function submitMessage() {
  if (messageInput.value) {
    emit('update', messageInput.value);
    isSubmitted.value = true;
    return;
  }

  activeGiftWrapping.value = false;
}
</script>

<i18n>
{
  "en": {
    "edit": "Edit",
    "delete": "Delete",
    "submit-message": "Submit message",
    "wrapItAsGiftTitle": "Wrap it as a gift for free",
    "whatIsGiftWrapping": "What is gift wrapping?",
    "title": "Wrap it as gift for free?",
    "wrapThisItem": "Wrap This Item"
  },
  "ar": {
    "edit": "تعديل",
    "delete": "حذف",
    "submit-message": "إرسال الرسالة",
    "wrapItAsGiftTitle": "يمكن تغليف المنتج كهديه مجانآ",
    "whatIsGiftWrapping": "ما هو تغليف الهدايا؟",
    "title": "قم بتغليفه كهديه"
  }
}
</i18n>
