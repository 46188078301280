<template>
  <div>
    <div
      v-if="count === 0 && $slots.empty"
      class="h-full"
      :class="{ 'opacity-50 pointer-events-none': disabled }"
      @click="() => !disabled && count < max && increment()"
    >
      <slot name="empty" />
    </div>
    <template v-else-if="count !== 0">
      <div
        class="grid grid-cols-3 h-full gap-x-3 items-center w-full justify-between rounded-md"
        :class="{ 'bg-primary-1-100': !inverted }"
      >
        <button
          v-if="minReached"
          data-attr="cartItem__decrease"
          class="h-full flex items-center justify-center rounded-xs"
          :class="{
            'text-white bg-primary-1-100': !inverted,
            'opacity-50 pointer-events-none': strict && minReached,
          }"
          @click="() => decrement()"
        >
          <svg-icon-minus
            width="15"
            height="15"
            :class="{
              'text-primary-1-100': variant === 'secondary' && inverted,
            }"
          />
        </button>

        <button
          v-else
          data-attr="cartItem__decrease"
          class="h-full flex items-center justify-center rounded-xs"
          :class="{
            'opacity-50 pointer-events-none': strict && minReached,
            'text-white bg-primary-1-100': !inverted,
          }"
          :disabled="count <= min"
          @click="() => count > min && decrement()"
        >
          <svg-icon-minus
            width="15"
            height="15"
            :class="{
              'text-primary-1-100': variant === 'secondary' && inverted,
            }"
          />
          <span v-if="showStepCount" class="ml-2"> ({{ step }}) </span>
        </button>
        <button class="font-bold" :class="{ 'bg-primary-1-100 text-white': !inverted }">
          {{ count }}
        </button>
        <button
          data-attr="cartItem__increase"
          class="h-full flex items-center justify-center rounded-xs"
          :class="{
            'opacity-50 pointer-events-none': count >= max,
            'text-white bg-primary-1-100': !inverted,
          }"
          :disabled="count >= max"
          @click="() => count < max && increment()"
        >
          <svg-icon-add width="15" height="15" :class="{ 'text-primary-1-100': variant === 'secondary' && inverted }" />
          <span v-if="showStepCount" class="ml-2"> ({{ step }}) </span>
        </button>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
defineComponent({
  name: 'QtyControls',
});

const props = defineProps({
  max: {
    type: Number,
    required: false,
    default: Number.MAX_SAFE_INTEGER,
  },
  min: {
    type: Number,
    required: false,
    default: 0,
  },
  step: {
    type: Number,
    required: false,
    default: 1,
  },
  value: {
    type: Number,
    required: false,
    default: 0,
  },
  strict: {
    type: Boolean,
    default: false,
  },

  // UI props

  showStepCount: {
    type: Boolean,
    default: true,
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'primary',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['input']);

const count = ref(props.value);

function increment() {
  if (!count.value) {
    count.value = props.min;
    emit('input', props.min);

    return;
  }
  count.value = Math.min(count.value + Number(props.step), Number(props.max));
  emit('input', count.value * 1);
}

function decrement() {
  if (count.value - Number(props.step) < props.min) {
    count.value = 0;
    emit('input', 0);
    return;
  }
  count.value = count.value - Number(props.step);
  emit('input', count.value);
}

const minReached = computed(() => {
  return count.value <= props.min;
});

watch(
  () => props.value,
  value => (count.value = value),
);
</script>
